<template>
  <div class="content">
    <div class="content__party">
      <h1 class="title title--big title--color">Список заявок: {{ $store.state.admin.request.statusName }}</h1>
      <div class="search">
        <v-select
          @input="searchClick"
          v-model="searchResult"
          :filterable="false"
          :options="searchOptions"
          @search="onSearch"
          :get-option-label="getLabel"
          placeholder="Поиск по заявкам (ФИО / ID)"
          class="select"
        >
          <template slot="open-indicator">
            <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                 fill="none">
              <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                    stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
          </template>
          <template slot="no-options">Введите свой запрос для поиска</template>
          <template slot="option" slot-scope="option">
            <div class="select__item d-center">
              {{ option.lastname }} {{ option.firstname }} {{ option.middlename }} (ID: {{ option.id }})
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">{{ option.lastname }} {{ option.firstname }} {{ option.middlename }} (ID: {{ option.id }})</div>
          </template>
        </v-select>
      </div>
    </div>
    <div class="content__party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
            :options="limitOptions"
            :selected="limitSelected"
            @updateOption="updateLimiter"
            :closeOnOutsideClick="true">
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ $store.state.admin.request.all }}</span>
        </div>
      </div>
    </div>
    <v-client-table :data="cards" :columns="columns" :options="options" ref="requestTable" class="table-default">
      <div slot="h__created_at" class="table-default__sortable table-default__sortable--desc" data-name="created_at" @click="getSort($event)">Дата создания</div>
      <div slot="h__lastname" class="table-default__sortable" data-name="lastname" @click="getSort($event)">ФИО</div>
      <div slot="h__tariff" class="table-default__sortable" data-name="tariff" @click="getSort($event)">Тариф</div>
      <div slot="h__type_delivery_name" class="table-default__sortable" data-name="type_delivery" @click="getSort($event)">Доставка</div>
      <div slot="created_at" slot-scope="props">
        {{ props.row.created_at }}
      </div>
      <div slot="type_of_name" slot-scope="props" class="table-default__logo">
        {{ props.row.type_of_name ? props.row.type_of_name : '-' }}
      </div>
      <div slot="lastname" slot-scope="props" class="table-default__left">
        {{ props.row.lastname }} {{ props.row.firstname }} {{ props.row.middlename }}
      </div>
      <div slot="tariff" slot-scope="props">
        {{ props.row.tariff.name }}
        <p>({{ props.row.tariff.price }} &#8381;)</p>
      </div>
      <div slot="type_delivery_name" slot-scope="props">
        {{ props.row.type_delivery_name }}
        <p v-if="props.row.type_delivery === 1 && props.row.pickup_city_name">({{ props.row.pickup_city_name }})</p>
      </div>
      <div slot="tooltip" slot-scope="props">
        <v-popover
          placement="left-start"

        >
          <button class="tooltip-target b3">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 4C10.1046 4 11 3.10457 11 2C11 0.89543 10.1046 0 9 0C7.89543 0 7 0.89543 7 2C7 3.10457 7.89543 4 9 4Z" fill="#2B93E7"/>
              <path d="M16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2C14 3.10457 14.8954 4 16 4Z" fill="#2B93E7"/>
              <path d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z" fill="#2B93E7"/>
            </svg>
          </button>
          <template slot="popover">
            <ul class="tooltip__list">
              <li class="tooltip__item">
                <img src="@/assets/img/edit-icon.svg" alt="Edit icon">
                <a :href="`/admin/request/${props.row.hash}`" class="tooltip__btn">Редактировать</a>
              </li>
              <li v-if="props.row.status === 1 || props.row.status === 5" class="tooltip__item">
                <img src="@/assets/img/mail-icon.svg" alt="Edit icon">
                <button @click="sendMail(props.row.hash)" class="tooltip__btn">Отправить письмо</button>
              </li>
            </ul>
          </template>
        </v-popover>
      </div>
    </v-client-table>
    <paginate
      v-if="$store.state.admin.request.count > 1"
      v-model="$store.state.admin.request.page"
      :page-count="$store.state.admin.request.count"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';
import { debounce } from 'lodash';

export default {
  name: 'RequestList',
  components: { dropdown },
  data() {
    return {
      columns: ['id', 'created_at', 'type_of_name', 'lastname', 'dob', 'tariff', 'type_delivery_name', 'tooltip'],
      current_order: 'created_at',
      options: {
        headings: {
          id: 'ID',
          type_of_name: 'Тип',
          dob: 'Дата рождения',
          tooltip: ''
        },
        pagination: { show: false },
        toggleGroups: true,
        sortable: [],
        perPage: 20,
        texts: {
          filter: '',
          filterPlaceholder: 'Поиск по таблице',
          noResults: 'Нет подходящих записей',
          filterBy: '',
          loading: 'Загрузка',
          count: '',
          limit: 'Количество записей на странице:',
          page: 'Страница:'
        },
        rowClassCallback() {
          return 'table-default__row';
        },
      },
      pagination: {
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: this.$store.state.admin.request.limit },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
      searchResult: null,
      searchOptions: []
    };
  },
  mounted() {
    this.limitSelected.name = 20;
    this.fetchCards(1, this.$store.state.admin.request.status, this.current_order, this.$store.state.admin.request.limit);
  },
  beforeDestroy() {
    this.updateLimiter({name: 20});
  },
  computed: {
    cards() {
      return this.$store.state.admin.request.cards;
    }
  },
  methods: {
    fetchCards(page = 1, statusCount, ordering, limit) {
      this.$store.dispatch('admin/request/GET_LIST', { page, statusCount, ordering, limit })
        .then(response => {
          this.$store.commit('admin/request/changeCount', response.data.pages);
          this.$store.commit('admin/request/setAll', response.data.count);
          this.$store.commit('admin/request/setLength', response.data.results.length);
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    clickPaginationCallback(page) {
      this.fetchCards(page, this.$store.state.admin.request.status, this.current_order, this.$store.state.admin.request.limit);
    },
    getSort(event) {
      const menuItemsAsc = document.getElementsByClassName('table-default__sortable--asc');
      menuItemsAsc.forEach((item) => {
        item.classList.remove('table-default__sortable--asc');
      });

      const menuItemsDesc = document.getElementsByClassName('table-default__sortable--desc');
      menuItemsDesc.forEach((item) => {
        item.classList.remove('table-default__sortable--desc');
      });

      if (this.current_order === event.target.dataset.name) {
        this.current_order = `-${event.target.dataset.name}`;

        const menuItems = document.getElementsByClassName('table-default__sortable--desc');
        menuItems.forEach((item) => {
          item.classList.remove('table-default__sortable--desc');
        });

        event.target.classList.add('table-default__sortable--asc');
      } else {
        this.current_order = event.target.dataset.name;

        const menuItems = document.getElementsByClassName('table-default__sortable--asc');
        menuItems.forEach((item) => {
          item.classList.remove('table-default__sortable--asc');
        });

        event.target.classList.add('table-default__sortable--desc');
      }

      this.fetchCards(this.$store.state.admin.request.page, this.$store.state.admin.request.status, this.current_order, this.$store.state.admin.request.limit);
    },
    updateLimiter(data) {
      this.$store.commit('admin/request/changeLimit', data.name);
      this.$refs.requestTable.setLimit(this.$store.state.admin.tableLimit);
      this.fetchCards(1, this.$store.state.admin.request.status, this.current_order, this.$store.state.admin.request.limit);
    },
    returnStart() {
      return (this.$store.state.admin.request.page * this.$store.state.admin.request.length) - (this.$store.state.admin.request.length - 1);
    },
    returnEnd() {
      return this.returnStart() + this.$store.state.admin.request.length - 1;
    },
    sendMail(hash) {
      this.$store.dispatch('admin/request/GET_MAIL', { hash })
        .then(() => {
          document.body.click();
          this.$notify({
            type: 'success',
            title: 'Уведомление!',
            text: 'Ссылка на оплату отправлена'
          });
        })
        .catch(error => {
          document.body.click();
          this.$notify({
            type: 'error',
            title: 'Внимание!',
            text: `${error.data.error}`
          });
        });
    },
    getLabel (option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    searchClick() {
      const a = document.createElement('a');
      a.href = `/admin/request/${this.searchResult.hash}`;
      a.style = 'display: none';
      a.click();
    },
    onSearch (search, loading) {
      loading(true);
      this.searchDelivery(loading, search, this);
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.$store.dispatch('admin/request/GET_QUERY', search).then(response => {
        vm.searchOptions = response.data.results;
        loading(false);
      });
    }, 350),
  }
};
</script>
